<template>
  <div ref="myLayout" class="app-menu navbar-menu">
    <!-- LOGO -->
    <div class="navbar-brand-box">
      <!-- Dark Logo-->
      <a href="index.html" class="logo logo-dark">
                    <span class="logo-sm">
                        <img src="../assets/images/logo-colored.svg" alt="" height="22">
                    </span>
        <span class="logo-lg">
                        <img src="../assets/images/logo-colored.svg" alt="" height="17">
                    </span>
      </a>
      <!-- Light Logo-->
      <a href="index.html" class="logo logo-light">
                    <span class="logo-sm">
                        <img src="../assets/images/logo-sm.png" alt="" height="50" style="height: 50px">
                    </span>
        <span class="logo-lg">
                        <img src="../assets/images/logo-light.png" alt="" height="50" style="height: 50px">
                    </span>
      </a>
      <button type="button" class="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover" id="vertical-hover">
        <i class="ri-record-circle-line"></i>
      </button>
    </div>

    <div id="scrollbar">
      <div class="container-fluid">

        <div id="two-column-menu">
        </div>
        <ul class="navbar-nav" id="navbar-nav">
          <li class="menu-title"><span data-key="t-menu">Menu</span></li>
          <li class="nav-item">
            <router-link   :to="{path: '/'}" class="nav-link" data-key="t-analytics">
              <i class="ri-dashboard-2-line"></i> <span data-key="t-dashboards">Tableau de bord</span></router-link>
          </li> <!-- end Dashboard Menu -->
          <li  class="nav-item">
            <a v-if="this.selectedCollectivite != null"  class="nav-link menu-link" href="#sidebarCollectivite" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarApps">
              <i class="ri-home-2-line "></i> <span data-key="t-apps">{{ this.selectedCollectivite.nom }}</span>
            </a>

            <a v-else-if="this.selectedRamification != null"  class="nav-link menu-link" href="#sidebarApps" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarApps">
              <i class="ri-home-2-line "></i> <span data-key="t-collectivite">{{ this.selectedRamification.nom }}</span>
            </a>
            <a v-else  class="nav-link menu-link" href="#sidebarCollectivite" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarApps">
              <i class="ri-home-2-line "></i> <span data-key="t-collectivite">Collectivité</span>
            </a>
            <div class="collapse menu-dropdown" id="sidebarCollectivite">
              <ul class="nav nav-sm flex-column">
                <li class="nav-item">
                  <router-link :to="{name: 'listeContribuables'}" class="nav-link" data-key="t-calendar"> Contribuables </router-link>
                </li>

                <li class="nav-item">
                  <router-link :to="{name: 'listeTransactions'}" class="nav-link" data-key="t-chat"> Transactions </router-link>
                </li>

              </ul>
            </div>
          </li>

        </ul>
      </div>
      <!-- Sidebar -->
    </div>

    <div class="sidebar-background"></div>
  </div>
</template>
<script>
export default {
  name: 'AppMenu',
  data() {
    return {
      user: null,
      userPermissions: null,
      selectedRamification: null,
      selectedCollectivite: null,
      navbarMenuHTML:null,
      horizontalMenuSplit: 7,
    }
  },
  methods: {



  },
  created() {
    try{
      this.$store.dispatch("setCollectivite", JSON.parse(localStorage.getItem('selectedCollectivite')));
      this.selectedCollectivite = this.$store.state.selectedCollectivite;

      this.$store.dispatch("setUser", JSON.parse(localStorage.getItem('user')));
      this.user = this.$store.state.user;
      this.$store.dispatch("setUserPermissions", JSON.parse(localStorage.getItem('userPermissions')));
      this.userPermissions =  this.$store.state.userPermissions;
      this.$store.dispatch("setRamification", JSON.parse(localStorage.getItem('selectedRamification')));
      this.selectedRamification =  this.$store.state.selectedRamification;

      //    console.log(this.userPermissions[0].id)

      // this.selectedCollectivite = this.$store.state.selectedCollectivite;
      // console.log(JSON.parse(localStorage.getItem('setUserPermissions')))

    }catch (e) {
      console.log(e)

    }
  },
  mounted() {


  //  this.init();

    //this.initLeftMenuCollapse();
    //this.toggleHamburgerMenu();
  }
}
</script>
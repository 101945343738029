




<template>
  <loading v-model:active="isLoading"
           :can-cancel="false"
           :on-cancel="onCancel"
           :is-full-page="fullPage"/>
  <!-- start page title -->
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <h4 class="mb-sm-0" v-if="selectedEntite != null">{{selectedEntite.nom}} > Tous les contribuables</h4>

        <div class="page-title-right">
          <ol class="breadcrumb m-0">
            <li class="breadcrumb-item"><router-link :to="{ name: 'home' }">Tableau de bord</router-link></li>
            <li class="breadcrumb-item"><router-link :to="{ name: 'home' }">Statistiques</router-link></li>
            <li class="breadcrumb-item active">Recensement par sous entité</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <h4 class="mb-sm-0">
          <!--
          <span v-if="entite !=null">{{ entite.text }}</span>
          <span v-if="departement !=null"> > {{ departement.text }}</span>
          <span v-if="quartier !=null"> > {{ quartier.text }}</span>
          <span v-if="zone !=null"> > {{ zone.text }}</span>-->
        </h4>
        <div class="page-title-right">
          <div>
            <div style="display: inline">
              <button  @click="filterByDay" class="btn btn-primary ">
                Aujourd'hui
              </button>
              <button   @click="filterByWeek" class="btn btn-soft-secondary ">
                Cette semaine
              </button>
              <button  @click="filterByMonth" class="btn btn-soft-secondary ">
                Ce mois
              </button>
              <button  @click="filterByYear" class="btn btn-soft-secondary ">
                Cette année
              </button>
              <div  class="btn-group" role="group">
                <button id="btnGroupDrop1" type="button" class="btn btn-soft-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="mdi mdi-dots-vertical align-middle"></i>
                </button>
                <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1" style="">
                  <li><button class="dropdown-item" @click="filterByYesterday">Hier</button></li>
                  <li><button class="dropdown-item" @click="filterByLastWeek">Semaine dernière</button></li>
                  <li><button class="dropdown-item" @click="filterByLastMonth">Mois dernier</button></li>
                  <li><Flatpickr
                      @change="onDateChange"
                      v-model="daterange"
                      :config="config"
                      class="form-control "
                      placeholder="Select date"/></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="row">
        <div class="row">
          <div class="col-md-6">
            <Select2 :placeholder="'Collectivité'"  v-model="selectedEntite" :options="listCollectivites"   @select="selectEntite($event)" @change="changeEntite($event)" />
          </div>
          <div class="col-md-6">
            <Select2 :placeholder="'Tous les departements'"  v-model="selectedDepartement" :options="listDepartements"  @select="selectDepartement($event)" @change="changeDepartement($event)" />
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-4">
            <Select2 :placeholder="'Tous les quartiers'"  v-model="selectedQuartier" :options="listQuartiers"   @select="selectQuartier($event)" @change="changeQuartier($event)" />
          </div>
          <div class="col-md-4">
            <Select2 :placeholder="'Tous les zones'"  v-model="selectedZone" :options="listZones"  @select="selectZone($event)" @change="changeZone($event)" />
          </div>
          <div class="col-md-4">
            <Select2 :placeholder="'Tous les secteurs'"  v-model="selectedSecteur" :options="listSecteurs"   @select="selectSecteur($event)" @change="changeSecteur($event)"  />
          </div>
        </div>
        <br>
        <div class="row mt-3">
          <div class="col-xl-12">
            <div class="card crm-widget">
              <div class="card-body p-0">
                <div class="row row-cols-xxl-4">
                  <div class="col text-start bg-warning">
                    <div class="py-4 px-3">
                      <h5 class=" text-uppercase fs-13">Contribuable(s) enrôlé(s) </h5>
                      <div class="d-flex align-items-center">
                        <div class="flex-shrink-0">
                          <i class="ri-user-3-line display-6 text-white"></i>
                        </div>
                        <div class="flex-grow-1 ms-3">
                          <router-link :to="{name: 'listeContribuables', query: {'entite': this.entite.id, 'ms': JSON.stringify(this.ms), from: this.from, to: this.to} }"> {{nombreTotalEnrol}}</router-link>
                          <!--<h2 class="mt-4 ff-secondary fw-semibold"><span class="counter-value" :data-target="report.contribuables"><router-link :to="{name : 'listeContribuables', query: { 'entite': (this.entite != null) ? this.entite.id: '', 'departement': (this.departement != null) ? this.departement.id: '', 'quartier': (this.quartier != null) ? this.quartier.id: '', 'zone': (this.zone != null) ? this.zone.id: '', 'secteur': (this.secteur != null) ? this.secteur.id: '' }}">{{ formattedNumber(this.report.contribuables) }}</router-link></span></h2>-->
                        </div>
                      </div>
                    </div>
                  </div><!-- end col -->
                  <!-- <div class="col text-start bg-info">
                     <div class="py-4 px-3 text-white">
                       <h5 class="text-uppercase fs-13 text-white">Assiète </h5>
                       <div class="d-flex align-items-center">
                         <div class="flex-shrink-0">
                           <i class="ri-money-dollar-box-line display-6 text-white"></i>
                         </div>
                         <div class="flex-grow-1 ms-3">
                           <h2 class="mb-0"><span class="counter-value text-white" data-target="{{dashboard.prevu}}">{{ formattedNumber(dashboard.prevu) }}</span></h2>
                         </div>
                       </div>
                     </div>
                   </div>--><!-- end col -->
                  <div class="col text-start bg-success">
                    <div class="py-4 px-3">
                      <h5 class="text-uppercase fs-13 text-white">Contribuables validé(s) <i class="ri-arrow-up-circle-line text-success fs-18 float-end align-middle"></i></h5>
                      <div class="d-flex align-items-center">
                        <div class="flex-shrink-0">
                          <i class="ri-money-dollar-box-line display-6 text-white"></i>
                        </div>
                        <div class="flex-grow-1 ms-3">
                          <h2><router-link :to="{name: 'listeContribuables', query: {'entite': this.entite.id, 'ms': JSON.stringify(this.ms), from: this.from, to: this.to, statut: 'Validé'} }">{{nombreValidation}}</router-link></h2>
                          <!--<h2 class="mt-4 ff-secondary fw-semibold"><span style="color: #fff" class="counter-value" :data-target="Number(report.valide)"><router-link class="text-white" :to="{name : 'listeContribuables', query: { 'entite': (this.entite != null) ? this.entite.id: '', 'departement': (this.departement != null) ? this.departement.id: '', 'quartier': (this.quartier != null) ? this.quartier.id: '', 'zone': (this.zone != null) ? this.zone.id: '', 'secteur': (this.secteur != null) ? this.secteur.id: '' }}">{{ formattedNumber(report.valide) }}</router-link></span></h2>-->
                        </div>
                      </div>
                    </div>
                  </div><!-- end col -->
                  <div class="col text-start bg-danger">
                    <div class="py-4 px-3">
                      <h5 class=" text-uppercase fs-13 text-white">contribuable(s) corrigé(s) </h5>
                      <div class="d-flex align-items-center">
                        <div class="flex-shrink-0">
                          <i class="ri-money-dollar-box-line display-6 text-white"></i>
                        </div>
                        <div class="flex-grow-1 ms-3">
                          <h2><router-link :to="{name: 'listeContribuables', query: {'entite': this.entite.id, 'ms': JSON.stringify(this.ms), from: this.from, to: this.to, statut: 'Corrigé'} }">{{nombreCorrige}}</router-link></h2>
                          <!--<h2 class="mt-4 ff-secondary fw-semibold text-white"><span class="counter-value" data-target="3"><router-link style="color: #fff" :to="{name : 'listeTransactions', query: { 'entite': (this.entite != null) ? this.entite.id: '', 'departement': (this.departement != null) ? this.departement.id: '', 'quartier': (this.quartier != null) ? this.quartier.id: '', 'zone': (this.zone != null) ? this.zone.id: '', 'secteur': (this.secteur != null) ? this.secteur.id: '' }}">{{ formattedNumber(report.totalNonValide )}}</router-link></span></h2>-->
                        </div>
                      </div>
                    </div>
                  </div><!-- end col -->
                </div><!-- end row -->
                <!-- end row -->
              </div><!-- end card body -->
            </div><!-- end card -->
          </div><!-- end col -->
        </div>
        <br>  <br>
       <div style="width: 100%; height: 15px; margin-top: 20px; margin-right: 20px; background-color: white"></div>
        <br>
        <div id="chart">
          <apexchart type="line" height="350" :options="chartOptions()" :series="series()"></apexchart>
        </div>
        <div style="width: 100%; height: 15px; background-color: white"></div>
        <br>





        <div class="row">
          <div class="card card-animate mt-3">
            <div class="card-body">
              <div class="row right p-2">
                <div class="col-md-2 mt-4">
                  <div v-if="user.groupe.nom === 'Super Admin'" class="btn-group " role="group">
                    <button id="btnGroupDrop1" type="button" class="btn btn-primary dropdown-toggle show" data-bs-toggle="dropdown" aria-expanded="true">
                      Action
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1" data-popper-placement="top-start" data-popper-reference-hidden="" data-popper-escaped="" style="position: absolute; inset: auto auto 0px 0px; margin: 0px; transform: translate(0px, -40px);">
                      <li><a  @click="bulkDelete()" class="dropdown-item" href="#">Supprimer</a></li>
                      <li><a class="dropdown-item" href="#">Dropdown link</a></li>
                    </ul>
                  </div>
                </div>


              </div>
              <div class="row">
                <div class="table-responsive">
                  <div class="col-sm-auto text-start">
                    <div>
                      <button @click="exportToPDF" type="button" class="btn btn-success add-btn" data-bs-toggle="modal" id="create-btn" data-bs-target="#showModal"><i class="ri-file-pdf-fill align-bottom me-1"></i> PDF</button>
                    </div>
                  </div>
                  <table id="element-to-convert" class="table text-start mb-0 table-bordered">
                    <thead class="table-light">
                    <tr class="text-center" >
                      <th class="bg-success" scope="col" >Date</th>
                      <th class="bg-success" scope="col">Total enrôlement</th>
                      <th class="bg-success" scope="col">Corrigé</th>
                      <th class="bg-success" scope="col">Validé</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in listEnrollement" :key="item.id">
                      <td>{{ new Date(item.date).toLocaleDateString('fr-FR', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) }}</td>
                      <td>{{item.total}}</td>
                      <td>{{item.corrige}}</td>
                      <td>{{item.valide}}</td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <th>TOTAL</th>
                    <th>{{ totalEnrollement }}</th>
                    <th>{{ totalCorrige }}</th>
                    <th>{{ totalValide }}</th>
                    </tfoot>
                  </table>
                  <!-- end table -->
                </div>
              </div>
              <!-- end table responsive -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import Repository from "@/repository/RamificationRepository";
import moment from "moment";
import html2pdf from "html2pdf.js"
import Select2 from 'vue3-select2-component';

import store from "@/store";
import RamificationRepository from "@/repository/RamificationRepository";
import EntiteRepository from "@/repository/EntiteRepository";
import fr from "flatpickr/dist/l10n/fr";
export default {
  name: 'listCategorieActivitePage',
  data() {
    return {
      listEnrollement: [],
      listCollectivites: [],
      ids: [],
      ms: [],
      listDepartements: [],
      listQuartiers: [],
      listZones: [],
      listSecteurs: [],
      daterange: "",
      entite: {},
      from: null,
      to: null,
      selectedAnnee: null,
      departement: null,
      quartier: null,
      zone:null,
      qt: [],
      nombreTotalEnrol: 0,
      nombreValidation: 0,
      nombreCorrige: 0,
      secteur: null,
      isLoading: false,
      fullPage: true,
      user: null,
      query: [],
      repository: new Repository(),
      orderBy: 'ram.nom',
      sortOrder: 'asc',
      itemsPerPage: 100,
      selectedEntite: null,
      allSelected: false,
      selectedItems: [],
      listRamifications: null,
      totalPages: 1,
      currentPage: 1,
      loading: false,
      ramificationRepo: new RamificationRepository(),
      entiteRepo: new EntiteRepository(),
      fields: [
        //{ colonne: '', operator: '', value: '' }
      ]

    }
  },
  computed: {
    totalEnrollement(){
      var sum = 0;
      this.listEnrollement.forEach(item => {
        sum = sum + Number(item.total)
      })
      return sum;
    },
    totalCorrige(){
      var sum = 0;
      this.listEnrollement.forEach(item => {
        sum = sum + Number(item.corrige)
      })
      return sum;
    },
    totalValide(){
      var sum = 0;
      this.listEnrollement.forEach(item => {
        sum = sum + Number(item.valide)
      })
      return sum;
    },
    config(){
      //var current_month = 12 - new Date().getMonth();
      return   {
        locale: fr.fr,
        mode: "range",
        dateFormat: "Y-m-d",
        firstDayOfWeek: 1,
        defaultDate: (this.selectedAnnee != null)?[this.selectedAnnee.text +"-" +new Date().getMonth()+"-01", this.selectedAnnee.text +"-12-"+new Date().getDay()]:[new Date().getFullYear()+'-'+ new Date().getMonth()+"-01", new Date().getFullYear()+'-'+new Date().getFullYear()+'-'+ new Date().getMonth()+"-31"],
      }

    },
    formatDate(value){
      return moment(String(value)).format('DD/MM/YYYY')
    },
    pages() {
      let pages = Array.from({length: this.totalPages}, (_, i) => i + 1)
      let currentPage = this.currentPage
      let visiblePages = 5
      let start = currentPage - Math.floor(visiblePages / 2)
      let end = currentPage + Math.floor(visiblePages / 2)
      if (start < 1) {
        end += 1 - start
        start = 1
      }
      if (end > this.totalPages) {
        start -= end - this.totalPages
        end = this.totalPages
      }
      if (start > 1) {
        pages.splice(0, start - 1, '...')
      }
      if (end < this.totalPages) {
        pages.splice(end, this.totalPages - end, '...')
      }
      return pages.slice(start - 1, end)
    },
  },
  methods: {
    series(){
      return [
        {
          name: 'Enrôlements',
          type: 'column',
          data: this.listEnrollement.map(item => item.total)
        },
        {
          name: 'Corrige',
          type: 'column',
          data:this.listEnrollement.map(item => item.corrige)
        },
        {
          name: 'Valide',
          type: 'column',
          data:this.listEnrollement.map(item => item.valide)
        },


      ]

    },
    chartOptions(){
      return {
        chart: {
          height: 350,
          type: 'line',
        },
        stroke: {
          width: [0, 4]
        },
        title: {
          text: 'Enrôlement par sous entite'
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1]
        },
        labels: this.chartLabel(),
        xaxis: {
          type: 'category'
        },
        yaxis: [{
          title: {
            text: 'Enrôlements',
          },

        },
          {
            opposite: true,
            title: {
              text: 'Enrôlements'
            }
          }]
      }

    },
    chartLabel(){
      var dates = [];
     // var d = "";
      const date = new Date();
      if (this.listEnrollement != null){
        this.listEnrollement.forEach(item => {
          //d = item.date.toString

          date.setMonth(Number(item.date) - 1);
          console.log(moment(item.date).format('MM'))
          if (item.date.length > 4)
            dates.push( moment(item.date).format('YYYY-MM-DD'))
          if(item.date.length <=2){
            date.setMonth(Number(item.date) - 1);
            //console.log(moment(item.date).format('MM'))
            dates.push( date.toLocaleString('fr-FR', { month: 'long' }))
          }


        })
      }
      return dates;
    },
    onDateChange(){
      this.fillTable();
    },
    filterByDay() {
      this.daterange = moment().format("YYYY-MM-DD") + ' au ' + moment().format("YYYY-MM-DD");
      this.fillTable()

    },
    filterByYesterday() {
      this.daterange = moment().subtract(1, 'days').format("YYYY-MM-DD") + ' au ' + moment().subtract(1, 'days').format("YYYY-MM-DD");
      this.fillTable()

    },
    filterByLastWeek() {
      this.daterange = moment().subtract(1, 'weeks').startOf('week').format("YYYY-MM-DD") + ' au ' + moment().subtract(1, 'weeks').endOf('week').format("YYYY-MM-DD");
      this.fillTable()

    },
    filterByLastMonth() {
      this.daterange = moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD") + ' au ' + moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD");
      this.fillTable()
      console.log(this.daterange)
    },
    filterByLastYear() {
      this.daterange = moment().subtract(1, 'years').startOf('year').format("YYYY-MM-DD") + ' au ' + moment().subtract(1, 'years').endOf('year').format("YYYY-MM-DD");
      this.fillTable()
      console.log(this.daterange)
    },
    filterByWeek() {
      this.daterange = moment().startOf('isoWeek').format("YYYY-MM-DD") + ' au ' + moment().endOf('isoWeek') .format("YYYY-MM-DD");
      this.fillTable()
      console.log(this.daterange);
    },
    filterByMonth() {
      this.daterange = moment().startOf('month') .format("YYYY-MM-DD") + ' au ' + moment().endOf('month'). format("YYYY-MM-DD");
      console.log(this.daterange);
      this.fillTable()
    },
    filterByYear() {
      this.daterange = moment().startOf('year') .format("YYYY-MM-DD") + ' au ' + moment().endOf('year'). format("YYYY-MM-DD");
      console.log(this.daterange)
      this.fillTable()
    },


    exportToPDF() {
      html2pdf(document.getElementById("element-to-convert"), {
        margin: 1,
        html2canvas:  { scale: 2 },
        pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
        filename: "liste-contribuables.pdf",
        jsPDF:        { unit: 'mm', format: 'a0', orientation: 'landscape' }
      });
    },
    selectEntite(val){
      this.entite = val;
      this.isLoading=true;
      //  this.selectedEntite = val;

      this.ramificationRepo.search([{colonne: 'ent.id', operator: 'eq', value: val.id}, {colonne: 'p.id', operator: 'is null', value: ''}], 'ent.nom', 'asc', 30, 1)
          .then(response => {
            response.data.forEach(item => {
              this.listDepartements.push({id: item.id, text: item.nom});
              this.fillTable();
            })
          });
    },
    changeEntite(val){
      this.entite = val;
      this.selectedEntite = val;
    },
    changeDepartement(val){
      this.selectedDepartement = val;
    },
    async selectDepartement(val){
      this.isLoading = true;
      this.departement = val;
      this.dashboard= {
        prevu: 0,
        contribuables: 0,
        agents: 0, paiement: 0
      };
      this.listQuartiers = [];
      this.listZones = [];
      this.listSecteurs = [];
      await this.ramificationRepo.search([{colonne: 'p.id', operator: 'eq', value: val.id}], 'ent.nom', 'asc', 30, 1)
          .then(response => {
            response.data.forEach(item => {
              this.listQuartiers.push({id: item.id, text: item.nom})

            })
            this.fillTable();

          })
    },
    changeStatut(val, index){
      this.fields[index].value = val;
      console.log(val)

    },
    changeCollectivite(val, index){
      this.fields[index].value = val;
      console.log(val)

    },
    changeRamification(val, index){
      this.fields[index].value = val;
      console.log(val)

    },
    changeQuartier(val){
      this.selectedQuartier = val;
    },
    selectQuartier(val){
      this.isLoading=true;
      this.quartier = val;
      this.dashboard= {
        prevu: 0,
        contribuables: 0,
        agents: 0,
        paiement: 0
      };
      this.listZones = [];
      this.listSecteurs = [];
      this.ramificationRepo.search([{colonne: 'p.id', operator: 'eq', value: val.id}], 'ent.nom', 'asc', 30, 1)
          .then(response => {
            response.data.forEach(item => {
              this.listZones.push({id: item.id, text: item.nom})
            })
            this.fillTable()
          })


    },
    changeZone(val){
      this.selectedZone = val;
    },
    selectZone(val){
      this.isLoading=true;
      this.zone = val;
      this.dashboard= {
        prevu: 0,
        contribuables: 0,
        agents: 0,
        paiement: 0
      };
      this.listSecteurs = [];
      this.ramificationRepo.search([{colonne: 'p.id', operator: 'eq', value: val.id}], 'ent.nom', 'asc', 30, 1)
          .then(response => {
            response.data.forEach(item => {
              this.listSecteurs.push({id: item.id, text: item.nom})
            })
            this.fillTable()
          })

    },
    async fillTable(){
      this.isLoading = true;
      this.from = this.daterange.includes('au')?this.daterange.split('au')[0]:this.daterange;
      this.to = this.daterange.includes('au')?this.daterange.split('au')[1]:this.daterange;
      this.ms = [];

      //console.log(this.fields)
      // console.log(this.entite);

      if (this.departement != null && this.quartier === null){
        this.ms = this.listQuartiers.map(item => item.id);
        this.ms.push(this.departement.id)

      }
      if (this.quartier != null && this.zone === null){

        this.ms = this.listZones.map(item => item.id);
        this.ms.push(this.quartier.id)

      }
      if (this.zone != null && this.secteur === null){

        this.ms = this.listSecteurs.map(item => item.id);
        this.ms.push(this.zone.id)

      }
      if (this.secteur != null ){
        this.ms = this.secteur;
      }
      console.log(this.ms)

      await this.repository.findOne("/v2/statistiques/enrollement/par-entite?entite="+this.ids+'&ramification='+this.ms+"&from=" + this.from + "&to="+this.to)
          .then(response => {
            console.log(response);
            this.listEnrollement = response.sort(function(a,b) {
              return b.total - a.total
            });
            this.nombreTotalEnrol = this.listEnrollement.reduce((accumulator,currentValue) => accumulator + currentValue.total,0);
            this.nombreValidation = this.listEnrollement.reduce((accumulator,currentValue) => accumulator += Number(currentValue.valide ?? 0),0);
            this.nombreCorrige = this.listEnrollement.reduce((accumulator,currentValue) => accumulator += Number(currentValue.corrige ?? 0),0);
            this.isLoading=false;
          });
    },


  },
  created() {
    this.selectedEntite = store.state.selectedCollectivite;
    this.$store.dispatch("setUser", JSON.parse(localStorage.getItem('user')));
    this.user = this.$store.state.user;
    this.daterange = moment().format("YYYY-MM-DD") + ' au ' + moment().format("YYYY-MM-DD");
    this.from = this.daterange.includes('au')?this.daterange.split('au')[0]:this.daterange;
    this.to = this.daterange.includes('au')?this.daterange.split('au')[1]:this.daterange;



    this.entiteRepo.search([{colonne: 'ent.enabled', operator: 'eq', value: 1}], 'ent.nom', 'asc', 30, 1)
        .then(response => {
          this.ids = [];
          this.ids = response.data.map(item => item.id);
          console.log(response.data);
          response.data.forEach(item => {
            this.listCollectivites.push({id: item.id, text: item.nom});
            this.fillTable();
          })
        })
  },
  components:{
    Loading,
    Select2
  }
}
</script>
<!--<template>
  <div style="background-color: white; margin-left:0; margin-right: 0;   height: 20px"></div>
  <h3></h3>

  <div id="chart">
    <apexchart type="line" height="350" :options="chartOptions" :series="series"></apexchart>
  </div>

  <br> <br>




</template>
<script>
export default {
  data() {
    return {
      a: '',
      series: [{
        name: 'Website Blog',
        type: 'column',
        data: [440, 505, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160]
      }, {
        name: 'Social Media',
        type: 'line',
        data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16]
      }],
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
        },
        stroke: {
          width: [0, 4]
        },
        title: {
          text: 'Traffic Sources'
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1]
        },
        labels: ['01 Jan 2001', '02 Jan 2001', '03 Jan 2001', '04 Jan 2001', '05 Jan 2001', '06 Jan 2001', '07 Jan 2001', '08 Jan 2001', '09 Jan 2001', '10 Jan 2001', '11 Jan 2001', '12 Jan 2001'],
        xaxis: {
          type: 'datetime'
        },
        yaxis: [{
          title: {
            text: 'Website Blog',
          },

        }, {
          opposite: true,
          title: {
            text: 'Social Media'
          }
        }]
      }
    }

  }
}

</script>
-->
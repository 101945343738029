



<template>
  <!-- start page title -->
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <h4 class="mb-sm-0">Modification enrôlement</h4>

        <div class="page-title-right">
          <ol class="breadcrumb m-0">
            <li class="breadcrumb-item"><router-link :to="{ name: 'home' }">Tableau de bord</router-link></li>
            <li class="breadcrumb-item"><router-link :to="{ name: 'home' }">Collectivité</router-link></li>
            <li class="breadcrumb-item active">Enrôlement modification</li>
          </ol>
        </div>

      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <h5 style="float:left">Contribuables</h5>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="row">
        <form > <!--@submit.prevent="editTypeEquipement"-->
          <div class="card card-animate">

            <div class="card-body">
              <div class="row text-start">
                <div class="col-sm-4">
                  <label for="Name" class="form-label">Nom</label>
                  <input  type="text" v-model="enrolement.contribuable.nom" class="form-control" required><!--v-model="typeEquipement.nom"-->
                  <div class="invalid-feedback">Veuillez entrer </div>
                </div>
                <div class="col-sm-4">
                  <label for="prenom" class="form-label">Prénom</label>
                  <input  type="text" v-model="enrolement.contribuable.prenom" class="form-control" required><!--v-model="typeEquipement.nom"-->
                  <div class="invalid-feedback">Veuillez entrer le nom de la taxe</div>
                </div>
                <div class="col-sm-4">
                  <label for="age" class="form-label">Age</label>
                  <input  type="text" v-model="enrolement.contribuable.age" class="form-control" required><!--v-model="typeEquipement.nom"-->
                  <div class="invalid-feedback">Veuillez entrer le nom de la taxe</div>
                </div>
              </div>
              <div class="row text-start mt-3">
                <div class="col-sm-4">
                  <label for="Adresse" class="form-label">Lieu de naissance</label>
                  <input  type="text" v-model="enrolement.contribuable.lieuNaissance" class="form-control" required>
                  <div class="invalid-feedback">Veuillez entrer </div>
                </div>
                <div class="col-sm-4">
                  <label for="telephone" class="form-label">Télephone</label>
                  <input  type="text" v-model="enrolement.contribuable.telephone" class="form-control" required>
                  <div class="invalid-feedback">Veuillez entrer </div>
                </div>
                <div class="col-sm-4">
                  <label for="firstName" class="form-label">Adresse</label>
                  <input  type="text" v-model="enrolement.contribuable.adresseResidence" class="form-control" required>
                  <div class="invalid-feedback">Veuillez entrer </div>
                </div>
              </div>
              <div class="row text-start mt-3">
                <div class="col-sm-4">
                  <label for="Adresse" class="form-label">Type pièce d'identité</label>
                  <input  type="text" v-model="enrolement.contribuable.typePieceIdentite" class="form-control" required>
                  <div class="invalid-feedback">Veuillez entrer </div>
                </div>
                <div class="col-sm-4">
                  <label for="telephone" class="form-label">Numéro pièce d'identité</label>
                  <input  type="text" v-model="enrolement.contribuable.numPieceIdentite" class="form-control" required>
                  <div class="invalid-feedback">Veuillez entrer </div>
                </div>
                <div class="col-sm-4">
                  <label for="telephone" class="form-label">Raison sociale</label>
                  <input  type="text" v-model="enrolement.contribuable.raisonSociale" class="form-control" required>
                  <div class="invalid-feedback">Veuillez entrer </div>
                </div>

              </div>
              <div class="row text-start mt-3">

                <div class="col-sm-4">
                  <label for="telephone" class="form-label">Nif</label>
                  <input  type="text" v-model="enrolement.contribuable.nif" class="form-control" required>
                  <div class="invalid-feedback">Veuillez entrer </div>
                </div>
                <div class="col-sm-4">
                  <label for="firstName" class="form-label">Moyen de paiement préferé</label>
                  <select  type="text" v-model="enrolement.contribuable.nomMoyenPaiementPrefere"  class="form-control" required><!--v-model="typeEquipement.entite"-->
                    <option >ok</option> <!--:key="item.id" :value="item.id" v-for="item in listCollectivite"-->
                  </select>
                  <div class="invalid-feedback">Veuillez entrer </div>
                </div>
                <div class="col-sm-4">
                  <label for="Adresse" class="form-label">Base taxable</label>
                  <select  type="text" v-model="enrolement.contribuable.baseTaxables"  class="form-control" required><!--v-model="typeEquipement.entite"-->
                    <option :key="item.id" :value="item.id" v-for="item in listBaseTaxable"  >
                      <span v-if="item.typeEquipement != null">{{item.typeEquipement.nom}}</span>
                      <span v-if="item.natureActivite != null">{{item.natureActivite.nom}}</span>

                    </option> <!--:key="item.id" :value="item.id" v-for="item in listCollectivite"-->
                  </select>
                  <div class="invalid-feedback">Veuillez entrer </div>
                </div>
              </div>
              <div class="row text-start mt-3">

                <div class="col-sm-4">
                  <label for="telephone" class="form-label">Activité</label>
                  <select @change="switchActivite"  v-model="enrolement.natureActivite" class=" form-select" required>
                    <option :value="activite.id" v-for="activite in choiceActivite" :key="activite"> {{ activite.nom }}</option>
                  </select>
                  <div class="invalid-feedback">Veuillez entrer </div>
                </div>
                <div class="col-sm-4">
                  <label for="firstName" class="form-label">Equipement</label>
                  <select @change="switchEquipement" v-model="enrolement.typeEquipement"  class=" form-select" >
                    <option v-for="item in choiceEquipement" :key="item.id" :value="item.id" >{{ item.nom }}</option>
                  </select>
                  <div class="invalid-feedback">Veuillez entrer </div>
                </div>

              </div>
              <div class="row text-start mt-3">


              </div>

              <div class="row mt-3">
                <div class="col-md-12">
                  <div class="form-check form-switch form-switch-success">
                    <input   class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" >
                    Active ?
                  </div>
                </div>
              </div>

            </div>
            <div class="card-footer">
              <div class="row">
                <button type="submit" @clik="modifier(enrolement.id)" class="btn btn-primary">Enregistrer</button>

              </div>
            </div>
          </div>

        </form>

      </div>
    </div>
  </div>
</template>

<script>
import EnrobRepository from "@/repository/EnrollementRepository";
import Taxe from "@/entity/Taxe";
import EntiteRepo from "@/repository/EntiteRepository";
import CategorieRepo from "@/repository/CategorieTaxeRepository";
import RamificationRepo from "@/repository/RamificationRepository";
import NatureActiviteRepo from "@/repository/NatureActiviteRepository";
import RepositoryTaxe from '@/repository/TaxeRepository'
import ContribualeRepo from "@/repository/ContribuableRepository";
import NatureActivite from "@/entity/NatureActivite";
import TypeEquipementRepository from "@/repository/TypeEquipementRepository";
import ApplicationRepository from "@/repository/ApplicationRepository";
//import Repository from '@/repository/NatureActiviteRepository'
import EquipementRepository from '@/repository/EquipementRepository'
import Enrolement from '@/entity/Enrolement'

import TaxeRepository from "@/repository/TaxeRepository";
//import EnrollementRepository from "@/repository/EnrollementRepository";
export default {
  name: 'EditEnrolementPage',

  data() {
    return {
      enrolement: new Enrolement(),
      enrobRepository: new EnrobRepository(),
      taxe: new Taxe(),
      choiceActivite: null,
      choiceEquipement: null,
      choiceCategorie: null,
      applications: [],
      listCollectivite: null,
      listRamifications: null,
      ramificationRepo: new RamificationRepo(),
      entiteRepo: new EntiteRepo(),
      natureRepo: new NatureActiviteRepo(),
      taxeRepo: new TaxeRepository(),
      appRepo: new ApplicationRepository(),
      categorieRepo: new CategorieRepo(),
      equipementRepo: new TypeEquipementRepository(),
      listCategorieTaxes: null,
      listTypeEquipements: null,
      fields: [
        { libelle: '', tarif: '', annee: '' }
      ],
      fieldsMontant: [
        { tarif: '', annee: '' }
      ],
      repositoryTaxe: new RepositoryTaxe(),
      contribRepo: new ContribualeRepo(),
      natureActivite: new NatureActivite(),
      equipementRepository: new EquipementRepository(),
      newEnrolement: [],
      listEquipements: null,
      listBaseTaxable: null


    }
  },
  computed: {
    selectedCollectivite(){
      if (this.taxe.entite != null){
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        //console.log(this.listTypeEquipements)
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.choiceEquipement = (this.listTypeEquipements != null) ? this.listTypeEquipements.filter(item => item.entite.id === this.taxe.entite) : [];
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.choiceCategorie = (this.listCategorieTaxes) ? this.listCategorieTaxes.filter(item => item.entite.id === this.taxe.entite) : [];
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.choiceActivite = this.listNatureActivites.filter(item => item.entite.id === this.taxe.entite);

        return this.listCollectivite.find(item => item.id === this.taxe.entite);
      }
      else return null;
    },
    selectedApplications(){
      return this.applications.filter(item => item.selected === true)
    }
  },
  methods: {
    switchEquipement(){
      this.taxe.natureActivite = null;
    },
    switchActivite(){
      this.taxe.typeEquipement = null;
    },
  modifier(id) {
    console.log(id);
    console.log(this.enrolement);
    this.newEnrolement = {
    };
    //this.contribRepo.edit(this.newEnrolement, id );
    console.log('ok')
  }
  },
  created() {
    this.entiteRepo.search([{ colonne: '', operator: '', value: '' }], 'ent.nom', 'asc', 30, 1)
        .then(response => {
          this.listCollectivite = response.data;
          //   console.log(this.listCollectivite);
        })

    this.categorieRepo.search([{ colonne: 'c.enabled', operator: 'eq', value: 1 }], 'ent.nom', 'asc', 3000, 1)
        .then(response => {
          this.listCategorieTaxes = response.data;
          // console.log(this.listCollectivite);
        })
    this.natureRepo.search([{ colonne: 'na.enabled', operator: 'eq', value: 1 }], 'ent.nom', 'asc', 3000, 1)
        .then(response => {
          this.listNatureActivites = response.data;
          // console.log(this.listCollectivite);
        })
    this.equipementRepo.search([{ colonne: 'teq.enabled', operator: 'eq', value: 1 }], 'ent.nom', 'asc', 3000, 1)
        .then(response => {
          this.listTypeEquipements = response.data;
          // console.log(this.listCollectivite);
        })

   /* this.frequenceRepo.search([{ colonne: 'fr.enabled', operator: 'eq', value: 1 }], 'fr.nom', 'asc', 30, 1)
        .then(response => {
          this.listFrequences = response.data;
          // console.log(this.listCollectivite);
        })*/

    this.repositoryTaxe.search([{ colonne: '', operator: '', value: '' }], 'entx.nom', 'asc', 165, 1)
        .then(response => {
          this.listBaseTaxable = response.data;
        }).catch(error => {
          console.log(error);
    })
    this.enrobRepository.find(this.$route.params.id)
        .then(response => {
          this.listEnrollement = response;
          this.enrolement = response;
          console.log(this.enrolement);
        })
    this.taxeRepo.find(this.$route.params.id)
        .then(response => {
          this.taxe = response;
          this.taxe.entite = response.entite.id
          this.taxe.appartenance = (response.appartenance != null)? response.appartenance.id : null;
          this.taxe.typeEquipement = (this.taxe.typeEquipement != null) ? this.taxe.typeEquipement.id : null;
          this.taxe.natureActivite = (this.taxe.natureActivite != null) ? this.taxe.natureActivite.id : null;
          this.taxe.categorie = (this.taxe.categorie != null) ? this.taxe.categorie.id : null;
          this.taxe.frequence = (this.taxe.frequence != null) ? this.taxe.frequence.id : null;
          if(this.taxe.applications.length > 0){
            if (this.taxe.applications[0].precisione.length >0){
              this.isPrecision = (this.taxe.applications[0].precisione.length > 0);
              this.annee = this.taxe.applications.filter(item => this.taxe.applications[0].ramification.id === item.ramification.id).map(app => app.annee );
            }
          }

          if (this.taxe.applications.length >0){
            this.fields = []

            this.fieldsMontant = (!this.isPrecision) ? this.taxe.applications.filter(item => this.taxe.applications[0].ramification.id === item.ramification.id).map(app => ({ annee: app.annee,tarif: app.tarif}) ) : [];
            for (const application of this.taxe.applications.filter(item => this.taxe.applications[0].ramification.id === item.ramification.id)){
              if(application.precisione.length > 0){
                for(const precis of application.precisione){
                  this.fields.push({libelle: precis.libelle, tarif: precis.tarif, annee: application.annee})
                }
              }
            }
          }
          //console.log(this.fieldsMontant)
          this.ramificationRepo.search([{ colonne: 'ent.id', operator: 'eq', value: this.taxe.entite }], 'ram.nom', 'asc', 500, 1)
              .then(response => {
                this.listRamifications = response.data;
                //   console.log(this.listRamifications);

                this.listRamifications.forEach(item => {
                  // console.log(item)
                  this.applications.push({annee: '','nomRamification': item.nom, 'ramification': item.id, precisions : this.fields,  'parent': null, 'type': item.type.nom, selected: true, tarif: this.montant, fieldsMontant : this.fieldsMontant})

                })
                //console.log(this.applications)
                this.isLoading=false;

              })
        });
  }
}
</script>


<!--<template>
  <div>Page de modification enrollement</div>
</template>
<script>

export default {
  data() {
    return {
      data: ''
    }
  }
}
</script>-->
import API from '@/helpers/Api';

export default class Contribuable {
    nom;
    prenom;
    age;
    lieuNaissance;
    raisonSociale;
    adresseResidence;
    nif;
    nomMoyenPaiementPrefere;
    telephone;
    baseTaxables;
    typePieceIdentite;
    numPieceIdentite;
    api = new API();


    constructor() {
        this.nom = '';
    }
}
<template>
  <loading v-model:active="isLoading"
           :can-cancel="false"
           :on-cancel="onCancel"
           :is-full-page="fullPage"/>
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <div class="page-title-right">
          <ol class="breadcrumb m-0">
            <li class="breadcrumb-item">
              <router-link :to="{ name: 'home' }">Statistiques</router-link>
            </li>
            <li class="breadcrumb-item">
              <router-link :to="{ name: 'home' }">Recouvrement</router-link>
            </li>
            <li class="breadcrumb-item active">Recouvrement par moyen de paiement</li>
          </ol>
        </div>
        <h4 class="mb-sm-0">
          <span v-if="entite !=null">{{ entite.text }}</span>
          <span v-if="departement !=null"> > {{ departement.text }}</span>
          <span v-if="quartier !=null"> > {{ quartier.text }}</span>
          <span v-if="zone !=null"> > {{ zone.text }}</span>
        </h4>
        <div class="page-title-right">
          <div>
            <div style="display: inline">
              <button @click="filterByDay" class="btn btn-primary ">
                Aujourd'hui
              </button>
              <button @click="filterByWeek" class="btn btn-soft-secondary ">
                Cette semaine
              </button>
              <button @click="filterByMonth" class="btn btn-soft-secondary ">
                Ce mois
              </button>
              <button @click="filterByYear" class="btn btn-soft-secondary ">
                Cette année
              </button>
              <div class="btn-group" role="group">
                <button id="btnGroupDrop1" type="button" class="btn btn-soft-secondary dropdown-toggle"
                        data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="mdi mdi-dots-vertical align-middle"></i>
                </button>
                <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1" style="">
                  <li>
                    <button class="dropdown-item" @click="filterByYesterday">Hier</button>
                  </li>
                  <li>
                    <button class="dropdown-item" @click="filterByLastWeek">Semaine dernière</button>
                  </li>
                  <li>
                    <button class="dropdown-item" @click="filterByLastMonth">Mois dernier</button>
                  </li>
                  <li>
                    <Flatpickr
                        @change="onDateChange"
                        v-model="daterange"
                        :config="config"
                        class="form-control "
                        placeholder="Select date"/>
                  </li>
                </ul>
              </div>
            </div>


          </div>

        </div>


      </div>
    </div>
  </div>

  <div class="row">

    <div class="col-md-12">

      <div class="row">
        <div class="col-md-6">
          <Select2 :placeholder="'Collectivité'" v-model="selectedEntite" :options="listCollectivites"
                   @select="selectEntite($event)" @change="changeEntite($event)"/>
        </div>
        <div class="col-md-6">
          <Select2 :placeholder="'Tous les departements'" v-model="selectedDepartement" :options="listDepartements"
                   @select="selectDepartement($event)" @change="changeDepartement($event)"/>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-4">
          <Select2 :placeholder="'Tous les quartiers'" v-model="selectedQuartier" :options="listQuartiers"
                   @select="selectQuartier($event)" @change="changeQuartier($event)"/>
        </div>
        <div class="col-md-4">
          <Select2 :placeholder="'Tous les zones'" v-model="selectedZone" :options="listZones"
                   @select="selectZone($event)" @change="changeZone($event)"/>
        </div>
        <div class="col-md-4">
          <Select2 :placeholder="'Tous les secteurs'" v-model="selectedSecteur" :options="listSecteurs"
                   @select="selectedSecteur($event)" @change="changeSecteur($event)"/>
        </div>
      </div>

      <br/>

      <!--      <div class="row">
              <form @submit.prevent="handleSearch">
                <div class="card">
                  <div class="card-body">
                    <table class="table table-sm table-bordered">
                      <thead>
                      <th>Champ</th>
                      <th>Operation</th>
                      <th>Valeur</th>
                      </thead>
                      <tbody>
                      <tr v-for="(field, index) in fields" :key="index">
                        <td>
                          <select v-model="field.colonne" class="form-select form-select-sm " style="width: 100%;">
                            <option v-bind:key="option.name" v-bind:value="option.name"
                                    v-for="option in repository.orderByOptions2">
                              {{ option.text }}
                            </option>
                          </select>
                        </td>
                        <td>
                          <select v-model="field.operator" class="form-select form-select-sm">
                            <option v-bind:key="option.name" v-for="option in repository.operatorOptions"
                                    v-bind:value="option.value">{{ option.text }}
                            </option>
                          </select>
                        </td>
                        <td>
                          <input style="width: 100%" v-model="field.value" type="text" class="form-control-sm">
                        </td>
                        <td>
                          <a @click="addFields" class="btn btn-sm btn-success"><i class="ri-add-line"/> </a>
                          <a v-if="index > 0" @click="removeField(index)" class="btn btn-sm btn-danger"><i
                              class="ri-delete-bin-2-line"/> </a>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                    <div class=" text-end">
                      <button type="submit" class="btn btn-success float-right">Rechercher</button>
                    </div>
                  </div>

                </div>
              </form>
            </div>-->
      <div class="row">
        <div class="card card-animate">

          <div class="card-body">
            <div class="row right p-2">

              <div id="chart">
                <apexchart type="line" height="350" :options="chartOptions()" :series="series()"></apexchart>
              </div>
            </div>

            <div class="row">
              <div class="table-responsive">
                <table class="table text-start mb-0 table-bordered">
                  <thead class="table-light">
                  <tr class="text-center">
                    <th class="bg-success" scope="col">Date</th>
                    <th class="bg-success" scope="col" colspan="8">Montant</th>
                  </tr>
                  <tr>
                    <th></th>
                    <th>Orange Money</th>
                    <th>Depot orange Money</th>
                    <th>Ecobank</th>
                    <th>Cheque</th>
                    <th>Mairie</th>
                    <th>Virement</th>
                    <th>Moov Money</th>
                    <th>Carte bancaire</th>

                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="trans in listTransactions" :key="trans">
                    <td v-if="trans.date.length>2">{{ trans.date }}</td>
                    <td v-else>{{ convertIntToMonth(trans.date )}}</td>
                    <td>{{ formattedNumber(Number(trans.orange)) }}</td>
                    <td>{{ formattedNumber(Number(trans.depot)) }}</td>
                    <td>{{ formattedNumber(Number(trans.ecobank)) }}</td>
                    <td>{{ formattedNumber(Number(trans.cheque)) }}</td>
                    <td>{{ formattedNumber(Number(trans.mairie)) }}</td>
                    <td>{{ formattedNumber(Number(trans.virement)) }}</td>
                    <td>{{ formattedNumber(Number(trans.moov)) }}</td>
                    <td>{{ formattedNumber(Number(trans.cb)) }}</td>

                  </tr>

                  </tbody>


                </table>
                <!-- end table -->
              </div>
            </div>
            <!-- end table responsive -->
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Repository from "@/repository/TransactionRepository";
import html2pdf from "html2pdf.js";
import store from "@/store";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

import moment from "moment";
import RamificationRepository from "@/repository/RamificationRepository";
import EntiteRepository from "@/repository/EntiteRepository";
import Select2 from "vue3-select2-component";

export default {
  name: 'listCategorieActivitePage',
  data() {
    return {
      listCollectivites: [],
      ids: [],
      from: null,
      to: null,
      selectedDepartement: null,
      listDepartements: [],
      listQuartiers: [],
      listZones: [],
      listSecteurs: [],
      entite: null,
      selectedAnnee: null,
      departement: null,
      quartier: null,
      zone: null,
      selectedEntite: null,
      qt: [],
      secteur: null,
      user: null,
      repository: new Repository(),
      orderBy: 'trans.dateTransaction',
      sortOrder: 'desc',
      daterange: null,

      selectedQuartier: null,
      selectedZone: null,
      selectedSecteur: null,
      itemsPerPage: 50,
      ms: [],
      allSelected: false,
      selectedItems: [],
      listTransactions: [],
      totalPages: 1,
      currentPage: 1,
      loading: false,
      ramificationRepo: new RamificationRepository(),
      entiteRepo: new EntiteRepository(),
      fields: [
        {colonne: '', operator: '', value: ''}
      ]

    }
  },
  computed: {},
  methods: {
    series() {
      return [
        {
          name: 'Ecobank Xpress',
          type: 'column',
          data: this.listTransactions.map(item => item.ecobank)
        },
        {
          name: 'Orange Money',
          type: 'column',
          data: this.listTransactions.map(item => item.orange)
        },
        {
          name: 'Moov Money',
          type: 'column',
          data: this.listTransactions.map(item => item.moov)
        },
        {
          name: 'Depot Orange Money',
          type: 'column',
          data: this.listTransactions.map(item => item.depot)
        },
        {
          name: 'Cheque',
          type: 'column',
          data: this.listTransactions.map(item => item.cheque)
        },
        {
          name: 'Payer à la mairie',
          type: 'column',
          data: this.listTransactions.map(item => item.mairie)
        },
        {
          name: 'Virement',
          type: 'column',
          data: this.listTransactions.map(item => item.virement)
        },
        {
          name: 'Carte bancaire',
          type: 'column',
          data: this.listTransactions.map(item => item.cb)
        },

      ]

    },
    chartOptions() {
      return {
        chart: {
          height: 350,
          type: 'line',
        },
        stroke: {
          width: [0, 4]
        },
        title: {
          text: 'Recouvrement par moyen de paiement'
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1]
        },
        labels: this.chartLabel(),
        xaxis: {
          type: 'date'
        },
        yaxis: [{
          title: {
            text: 'Moyens de paiements',
          },

        },
          {
            opposite: true,
            title: {
              text: 'Moyens de paiements'
            }
          }]
      }

    },
    convertIntToMonth(month){
      return moment(
          month,           // Desired month
          'MM'            // Tells MomentJs the number is a reference to month
      ).format('MMMM')
    },
    chartLabel() {
      if (this.listTransactions != null) {
        return this.listTransactions.map(item => (item.date.length>2)?moment(item.date).format('DD-MM-YYYY'):moment(
            item.date,           // Desired month
            'MM'            // Tells MomentJs the number is a reference to month
        ).format('MMMM'))
      }
      return [];
    },
    formatDate(value) {
      return moment(String(value)).format('DD/MM/YYYY')
    },
    formattedNumber(number) {
      if (number != null)
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      return 0;
    },
    onDateChange() {
      this.fillTable();
    },

    filterByDay() {
      this.daterange = moment().format("YYYY-MM-DD") + ' au ' + moment().format("YYYY-MM-DD");
      this.fillTable()
      console.log(this.daterange)
    },
    filterByYesterday() {
      this.daterange = moment().subtract(1, 'days').format("YYYY-MM-DD") + ' au ' + moment().subtract(1, 'days').format("YYYY-MM-DD");
      this.fillTable()
      console.log(this.daterange)
    },
    filterByLastWeek() {
      this.daterange = moment().subtract(1, 'weeks').startOf('week').format("YYYY-MM-DD") + ' au ' + moment().subtract(1, 'weeks').endOf('week').format("YYYY-MM-DD");
      this.fillTable()
      console.log(this.daterange)
    },
    filterByLastMonth() {
      this.daterange = moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD") + ' au ' + moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD");
      this.fillTable()
      console.log(this.daterange)
    },
    filterByLastYear() {
      this.daterange = moment().subtract(1, 'years').startOf('year').format("YYYY-MM-DD") + ' au ' + moment().subtract(1, 'years').endOf('year').format("YYYY-MM-DD");
      this.fillTable()
      console.log(this.daterange)
    },
    filterByWeek() {
      this.daterange = moment().startOf('isoWeek').format("YYYY-MM-DD") + ' au ' + moment().endOf('isoWeek').format("YYYY-MM-DD");
      console.log(this.daterange);
      this.fillTable()
    },
    filterByMonth() {
      this.daterange = moment().startOf('month').format("YYYY-MM-DD") + ' au ' + moment().endOf('month').format("YYYY-MM-DD");
      console.log(this.daterange);
      this.fillTable()
    },
    filterByYear() {
      this.daterange = moment().startOf('year').format("YYYY-MM-DD") + ' au ' + moment().endOf('year').format("YYYY-MM-DD");
      this.fillTable();
      console.log(this.daterange)
    },
    selectEntite(val) {
      this.entite = val;
      this.isLoading = true;
      //  this.selectedEntite = val;

      this.ramificationRepo.search([{colonne: 'ent.id', operator: 'eq', value: val.id}, {
        colonne: 'p.id',
        operator: 'is null',
        value: ''
      }], 'ent.nom', 'asc', 30, 1)
          .then(response => {
            response.data.forEach(item => {
              this.listDepartements.push({id: item.id, text: item.nom});
              this.fillTable();

            })
          });
    },
    changeEntite(val) {
      this.entite = val;
      this.selectedEntite = val;
    },
    changeDepartement(val) {
      this.selectedDepartement = val;
    },
    async selectDepartement(val) {
      this.isLoading = true;
      this.departement = val;
      this.dashboard = {
        prevu: 0,
        contribuables: 0,
        agents: 0, paiement: 0
      };
      this.listQuartiers = [];
      this.listZones = [];
      this.listSecteurs = [];
      await this.ramificationRepo.search([{colonne: 'p.id', operator: 'eq', value: val.id}], 'ent.nom', 'asc', 30, 1)
          .then(response => {
            response.data.forEach(item => {
              this.listQuartiers.push({id: item.id, text: item.nom})
              this.ms = this.listQuartiers.map(item => item.id);

            })
            this.fillTable();

          })
    },
    changeQuartier(val) {
      this.selectedQuartier = val;
    },
    selectQuartier(val) {
      this.isLoading = true;
      this.quartier = val;
      this.dashboard = {
        prevu: 0,
        contribuables: 0,
        agents: 0,
        paiement: 0
      };
      this.listZones = [];
      this.listSecteurs = [];
      this.ramificationRepo.search([{colonne: 'p.id', operator: 'eq', value: val.id}], 'ent.nom', 'asc', 30, 1)
          .then(response => {
            response.data.forEach(item => {
              this.listZones.push({id: item.id, text: item.nom})
              this.ms = this.listZones.map(item => item.id);

            })
            this.fillTable();
          })


    },
    exportToPDF() {
      html2pdf(document.getElementById("element-to-convert"), {
        margin: 1,
        html2canvas: {scale: 2},
        pagebreak: {mode: ['avoid-all', 'css', 'legacy']},
        filename: "liste-contribuables.pdf",
        jsPDF: {unit: 'mm', format: 'a0', orientation: 'landscape'}
      });
    },
    changeZone(val) {
      this.selectedZone = val;
    },
    selectZone(val) {
      this.isLoading = true;
      this.zone = val;
      this.dashboard = {
        prevu: 0,
        contribuables: 0,
        agents: 0,
        paiement: 0
      };
      this.listSecteurs = [];
      this.ramificationRepo.search([{colonne: 'p.id', operator: 'eq', value: val.id}], 'ent.nom', 'asc', 30, 1)
          .then(response => {
            response.data.forEach(item => {
              this.listSecteurs.push({id: item.id, text: item.nom})
            })
            this.fillTable();
          })

    },
    deleteNatureActivite(id) {
      if (confirm("Etes-vous sûr de supprimer cet élément ?")) {
        this.loading = true;
        this.repository.supprimer(id);
        this.fillTable();
        this.loading = false;
      }


    },
    bulkDelete() {
      if (confirm("Etes vous sûr de vouloir supprimer cet élément ?")) {
        this.loading = true;

        this.selectedItems.forEach(element => {
          this.repository.supprimer(element);
          //console.log(element);
        })
        this.loading = false;
        this.fillTable();
      }

    },
    selectAll() {
      if (this.allSelected === false) {
        this.allSelected = true;
        this.selectedItems = this.listTransactions.map(type => type.id);
        //  console.log(this.selectedItems);
      } else {
        this.allSelected = false;
        this.selectedItems = [];

        // console.log(this.selectedItems);

      }

      //console.log(this.allSelected)
    },
    goToPage(page) {
      this.currentPage = page;
      this.fillTable();
    },
    addFields() {
      this.fields.push({colonne: '', operator: '', value: ''});
    },
    removeField(index) {
      this.fields.splice(index, 1);
    },
    handleItemPerPage(event) {
      this.itemsPerPage = event.target.value;
      //   console.log('total de page ' + this.pages)
      // console.log(this.itemsPerPage)
      this.currentPage = 1;

      this.fillTable();


    },
    handleSearch() {
      //console.log(this.fields);
      this.currentPage = 1;
      this.fillTable();
    },

    handleOrderBy(event) {
      this.orderBy = event.target.value;
      this.fillTable();

      //console.log(this.orderBy)

    },
    handleSortOrder(event) {
      this.sortOrder = event.target.value;
      // console.log(this.sortOrder)
      this.fillTable();

    },
    async fillTable() {
      try {
        this.isLoading = true;
        this.from = (this.daterange.includes('au')) ? this.daterange.split('au')[0].trim() : this.daterange;
        this.to = (this.daterange.includes('au')) ? this.daterange.split('au')[1].trim() : this.daterange.split('au')[1];


        //console.log(trResponse)
        this.listTransactions = await this.repository.findOne("/v2/statistiques/transactions/moyen-par-paiement?entite=" + this.ids + "&ramification=" + this.ms + "&from=" + this.from + "&to=" + this.to);
        //console.log(this.listTransactions);
        this.isLoading = false;

      } catch (e) {
        console.log(e);
      }
    },


  },
  created() {
    this.selectedEntite = store.state.selectedCollectivite;
    this.$store.dispatch("setUser", JSON.parse(localStorage.getItem('user')));
    this.user = this.$store.state.user;
    this.daterange = moment().startOf('week').format("YYYY-MM-DD") + ' au ' + moment().endOf('week').format("YYYY-MM-DD");
    // console.log(this.daterange)

    this.entiteRepo.search([{colonne: 'ent.enabled', operator: 'eq', value: 1}], 'ent.nom', 'asc', 30, 1)
        .then(response => {
          this.ids = [];
          this.ids = response.data.map(item => item.id);
          response.data.forEach(item => {
            this.listCollectivites.push({id: item.id, text: item.nom});
            this.fillTable();
            console.log(this.listTransactions);
          })
        })


  },
  components: {
    Loading,
    Select2


  }
}
</script>
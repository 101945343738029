import ListeEnrollement from "@/components/enrollement/ListeEnrollement";
import EditEnrollement from "@/components/enrollement/EditEnrollement.vue";

export default [

    {
        path: '/enrollements',
        name: 'listEnrollement',
        component: ListeEnrollement
    },
    {
        path: '/enrollements/:id',
        name: 'editEnrollements',
        component: EditEnrollement
    }
]
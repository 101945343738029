import API from "@/helpers/Api";
import Contribuable from "@/entity/Contribuable";

export default class Enrolement {
             id;
             contribuable;
             api = new API();
             numKlis
             typePieceIdentite;
             lieuNaissance;
             equipements;
             raisonSociale;
             nif;
             enabled;
             createdAt;
             updatedAt;
             createdBy;
             nom;
             prenom;
             fullName;
             type;
             enabled;
             groupe;
             entites;
             ramifications;
             natureActivite
             isConfirmed;
             createdAt;
             updatedAt;
             variation;
             montant;
    constructor() {
      this.contribuable = new Contribuable();
    }
}
<template>
  <loading v-model:active="isLoading"
           :can-cancel="false"
           :on-cancel="onCancel"
           :is-full-page="fullPage"/>
  <!-- start page title -->
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <h4 class="mb-sm-0" v-if="selectedEntite != null">{{ selectedEntite.nom }} > Sous-entités</h4>

        <div class="page-title-right">
          <ol class="breadcrumb m-0">
            <li class="breadcrumb-item">
              <router-link :to="{ name: 'home' }">Statistiques</router-link>
            </li>
            <li class="breadcrumb-item">
              <router-link :to="{ name: 'home' }">Recouvrement</router-link>
            </li>
            <li class="breadcrumb-item active">Recouvrement par sous-entité</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <h4 class="mb-sm-0">
        </h4>
        <div class="page-title-right">
          <div>
            <div style="display: inline">


              <button @click="filterByDay" class="btn btn-primary ">
                Aujourd'hui
              </button>
              <button @click="filterByWeek" class="btn btn-soft-secondary ">
                Cette semaine
              </button>
              <button @click="filterByMonth" class="btn btn-soft-secondary ">
                Ce mois
              </button>
              <button @click="filterByYear" class="btn btn-soft-secondary ">
                Cette année
              </button>
              <div class="btn-group" role="group">
                <button id="btnGroupDrop1" type="button" class="btn btn-soft-secondary dropdown-toggle"
                        data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="mdi mdi-dots-vertical align-middle"></i>
                </button>
                <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1" style="">
                  <li>
                    <button class="dropdown-item" @click="filterByYesterday">Hier</button>
                  </li>
                  <li>
                    <button class="dropdown-item" @click="filterByLastWeek">Semaine dernière</button>
                  </li>
                  <li>
                    <button class="dropdown-item" @click="filterByLastMonth">Mois dernier</button>
                  </li>
                  <li>
                    <Flatpickr
                        @change="onDateChange"
                        v-model="daterange"
                        :config="config"
                        class="form-control "
                        placeholder="Select date"/>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="row">
        <div class="row">
          <div class="col-md-6">
            <Select2 :placeholder="agent" @select="selectAgent($event)" @change="changeAgent($event)"
                     class="form-control form-select" v-model="ramificationId"
                     :options="listQuartiers"/>
          </div>
        </div>
        <br>
        <div v-if="listeRamification === false" class="row mt-3">
        </div>

        <br> <br>
        <div style="width: 100%; height: 15px;margin-top: 20px; background-color: white"></div>
        <br>
        <div id="chart" v-if="listeRamification === false">
          <apexchart type="line" height="350" :options="chartOptions()" :series="series()"></apexchart>
        </div>
        <div style="width: 100%; height: 15px; background-color: white"></div>
        <br>

        <div class="row">
          <div class="card card-animate mt-3">
            <div class="card-body">
              <div class="row right p-2">
                <div class="col-md-2 mt-4">
                  <div v-if="user.groupe.nom === 'Super Admin'" class="btn-group " role="group">
                    <button id="btnGroupDrop1" type="button" class="btn btn-primary dropdown-toggle show"
                            data-bs-toggle="dropdown" aria-expanded="true">
                      Action
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1" data-popper-placement="top-start"
                        data-popper-reference-hidden="" data-popper-escaped=""
                        style="position: absolute; inset: auto auto 0px 0px; margin: 0px; transform: translate(0px, -40px);">
                      <li><a @click="bulkDelete()" class="dropdown-item" href="#">Supprimer</a></li>
                      <li><a class="dropdown-item" href="#">Dropdown link</a></li>
                    </ul>
                  </div>
                </div>


              </div>

              <div v-if="listeRamification === false" class="row">
                <div class="table-responsive">
                  <div class="col-sm-auto text-start">
                    <div>
                      <button @click="exportToPDF" type="button" class="btn btn-success add-btn" data-bs-toggle="modal"
                              id="create-btn" data-bs-target="#showModal"><i
                          class="ri-file-pdf-fill align-bottom me-1"></i> PDF
                      </button>
                    </div>
                  </div>
                  <table id="element-to-convert" class="table text-start mb-0 table-bordered text-center">
                    <thead class="table-light">
                    <tr class="text-center">
                      <th scope="col" class="bg-success">
                        <div class="form-check">
                          <input v-model="allSelected" @click="selectAll" class="form-check-input" type="checkbox"
                                 value="" id="responsivetableCheck">
                          <label class="form-check-label" for="responsivetableCheck"></label>
                        </div>
                      </th>
                      <th class="bg-success" scope="col">#</th>
                      <th class="bg-success" scope="col">Date</th>
                      <th class="bg-success" scope="col">Total collecté</th>
                      <th class="bg-success"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, index) in stats " :key="item.id">
                      <td><input class="form-check-input" type="checkbox" v-model="selectedItems"
                                 v-bind:value="item.id"/></td>
                      <td>{{ index + 1 }}</td>
                      <td>{{
                          item.date
                        }}
                      </td><!--{{ item.createdAt.toString().slice(0,10) }}-->
                      <td>{{ item.total }}</td>
                      <td>
                        <div class="dropdown">
                          <a href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown"
                             aria-expanded="false">
                            <i class="ri-more-2-fill"></i>
                          </a>

                          <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                          </ul>
                        </div>
                      </td>
                    </tr>

                    </tbody>
                    <tfoot class="table-light">
                    <tr class="text-center">
                      <th scope="col" class="bg-success">
                        <div class="form-check">
                          <input v-model="allSelected" @click="selectAll" class="form-check-input" type="checkbox"
                                 value="" id="responsivetableCheck">
                          <label class="form-check-label" for="responsivetableCheck"></label>
                        </div>
                      </th>
                      <th class="bg-success" scope="col">#</th>
                      <th class="bg-success" scope="col">Date</th>
                      <th class="bg-success" scope="col">Total collecté</th>
                      <th class="bg-success"></th>
                    </tr>
                    </tfoot>
                  </table>
                  <!-- end table -->
                </div>
              </div>
<!--              <div v-else class="row">
                <div class="table-responsive">
                  <div class="col-sm-auto text-start">
                    <div>
                      <button @click="exportToPDF" type="button" class="btn btn-success add-btn" data-bs-toggle="modal"
                              id="create-btn" data-bs-target="#showModal"><i
                          class="ri-file-pdf-fill align-bottom me-1"></i> PDF
                      </button>
                    </div>
                  </div>
                  <table id="element-to-convert" class="table text-start mb-0 table-bordered text-center">
                    <thead class="table-light">
                    <tr class="text-center">
                      <th class="bg-success" scope="col">#</th>
                      <th class="bg-success" scope="col">Date</th>
                      <th class="bg-success" scope="col">Sous-entité</th>
                      <th class="bg-success" scope="col">Total collecté</th>
                      <th class="bg-success"></th>

                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, index) in listQuartiers " :key="item.id">
                      &lt;!&ndash;<input class="form-check-input" type="checkbox" v-model="selectedItems" v-bind:value="item.id" />&ndash;&gt;
                      <td>{{ index + 1 }}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <div class="dropdown">
                          <a href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown"
                             aria-expanded="false">
                            <i class="ri-more-2-fill"></i>
                          </a>

                          <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                          </ul>
                        </div>
                      </td>
                    </tr>

                    </tbody>
                    <tfoot class="table-light">
                    <tr class="text-center">
                      <th class="bg-success" scope="col">#</th>
                      <th class="bg-success" scope="col">Date</th>
                      <th class="bg-success" scope="col">Sous-entité</th>
                      <th class="bg-success" scope="col">Total collecté</th>
                      <th class="bg-success"></th>
                    </tr>
                    </tfoot>
                  </table>
                  &lt;!&ndash; end table &ndash;&gt;
                </div>
              </div>-->
              <!-- end table responsive -->
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-sm-12 col-md-12 ">
                  <div style="display: flex; justify-content: flex-end"
                       class="dataTables_paginate paging_simple_numbers text-end" id="scroll-horizontal_paginate">
                    <ul class="pagination">
                      <li v-if="totalPages<=1" class="paginate_button page-item previous disabled"
                          id="scroll-horizontal_previous">
                        <button @click="goToPage((currentPage-1))" aria-controls="scroll-horizontal" data-dt-idx="0"
                                tabindex="0" class="page-link">Précédent
                        </button>
                      </li>
                      <li v-else-if="totalPages>1" class="paginate_button page-item previous disabled"
                          id="scroll-horizontal_previous">
                        <button @click="goToPage((currentPage-1))" aria-controls="scroll-horizontal" data-dt-idx="0"
                                tabindex="0" class="page-link">Précédent
                        </button>
                      </li>
                      <li v-for="page in pages" :key="page" class="paginate_button page-item active">
                        <button v-if="currentPage === page" @click="goToPage(page)" aria-controls="scroll-horizontal"
                                data-dt-idx="1" tabindex="0" class="page-link active">{{ page }}
                        </button>
                        <button v-else @click="goToPage(page)" aria-controls="scroll-horizontal" data-dt-idx="1"
                                tabindex="0" class="btn btn-light">{{ page }}
                        </button>

                      </li>
                      <li v-if="totalPages<=1" class="paginate_button page-item next disabled"
                          id="scroll-horizontal_next">
                        <button @click="goToPage((currentPage+1))" aria-controls="scroll-horizontal" data-dt-idx="3"
                                tabindex="0" class="page-link">Next
                        </button>
                      </li>
                      <li v-else-if="totalPages>1" class="paginate_button page-item next " id="scroll-horizontal_next">
                        <button @click="goToPage((currentPage+1))" aria-controls="scroll-horizontal" data-dt-idx="3"
                                tabindex="0" class="page-link">Next
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import Utilisateur from "@/entity/User";
import Repository from '@/repository/UserRepository'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
//import Repository from '@/repository/ContribuableRepository'
import moment from "moment";
import html2pdf from "html2pdf.js"
import Select2 from 'vue3-select2-component';

import store from "@/store";
import Utilisateur from '@/repository/AgentRepository';
import Ramification from "@/repository/RamificationRepository";
import EntiteRepository from "@/repository/EntiteRepository";
import fr from "flatpickr/dist/l10n/fr";

export default {
  name: 'listCategorieActivitePage',
  data() {
    return {
      listCollectivites: [],
      ids: [],
      ms: [],
      listeRamification: true,
      ramificationId: null,
      listDepartements: [],
      listQuartiers: [],
      listZones: [],
      daterange: "",
      nombreTotalEnrol: 0,
      nombreValidation: 0,
      nombreCorrige: 0,
      //daterange: null,
      listSecteurs: [],
      entite: null,
      selectedAnnee: null,
      departement: null,
      quartier: null,
      zone: null,
      qt: [],
      utilisateurRepo: new Utilisateur(),
      secteur: null,
      isLoading: false,
      fullPage: true,
      user: null,
      query: [],
      from: null,
      to: null,
      utilisateur: new Utilisateur(),
      repository: new Repository(),
      orderBy: 'user.nom',
      sortOrder: 'asc',
      itemsPerPage: 100,
      selectedEntite: null,
      allSelected: false,
      selectedItems: [],
      listContribuables: null,
      listRamifications: [],
      stats: [],
      totalPages: 1,
      currentPage: 1,
      loading: false,
      ramificationRepo: new Ramification(),
      entiteRepo: new EntiteRepository(),
      fields: [
        //{ colonne: '', operator: '', value: '' }
      ]

    }
  },
  computed: {
    config() {
      //var current_month = 12 - new Date().getMonth();
      return {
        locale: fr.fr,
        mode: "range",
        dateFormat: "Y-m-d",
        firstDayOfWeek: 1,
        defaultDate: (this.selectedAnnee != null) ? [this.selectedAnnee.text + "-" + new Date().getMonth() + "-01", this.selectedAnnee.text + "-12-" + new Date().getDay()] : [new Date().getFullYear() + '-' + new Date().getMonth() + "-01", new Date().getFullYear() + '-' + new Date().getFullYear() + '-' + new Date().getMonth() + "-31"],
      }

    },
    formatDate(value) {
      return moment(String(value)).format('DD/MM/YYYY')
    },
    pages() {
      let pages = Array.from({length: this.totalPages}, (_, i) => i + 1)
      let currentPage = this.currentPage
      let visiblePages = 5
      let start = currentPage - Math.floor(visiblePages / 2)
      let end = currentPage + Math.floor(visiblePages / 2)
      if (start < 1) {
        end += 1 - start
        start = 1
      }
      if (end > this.totalPages) {
        start -= end - this.totalPages
        end = this.totalPages
      }
      if (start > 1) {
        pages.splice(0, start - 1, '...')
      }
      if (end < this.totalPages) {
        pages.splice(end, this.totalPages - end, '...')
      }
      return pages.slice(start - 1, end)
    },

  },
  methods: {
    series() {
      return [
        {
          name: 'Recouvrements',
          type: 'column',
          data: this.stats.map(item => item.total)
        }
      ]

    },
    chartOptions() {
      return {
        chart: {
          height: 350,
          type: 'line',
        },
        stroke: {
          width: [0, 4]
        },
        title: {
          text: 'Recouvrement par sous-entité'
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1]
        },
        labels: this.chartLabel(),
        xaxis: {
          type: 'date'
        },
        yaxis: [{
          title: {
            text: 'Recouvrements',
          },

        }]
      }
    },
    chartLabel() {
      return this.stats.map(item =>item.date)

    },

    selectEntite(val) {
      this.entite = val;
      this.isLoading = true;
      //  this.selectedEntite = val;
      this.ramificationRepo.search([{colonne: 'ent.id', operator: 'eq', value: val.id}, {
        colonne: 'p.id',
        operator: 'is null',
        value: ''
      }], 'ent.nom', 'asc', 30, 1)
          .then(response => {
            response.data.forEach(item => {
              this.listDepartements.push({id: item.id, text: item.nom});
              this.fillTable();
            })
          });
    },
    changeEntite(val) {
      this.entite = val;
      this.selectedEntite = val;
    },
    selectAgent(val) {
      this.ramificationId = val.id
      this.fillTable()
    },
    changeAgent(val) {
      this.ramificationId = val.id;
      this.fillTable()
    },
    changeDepartement(val) {
      this.selectedDepartement = val;
    },
    async selectDepartement(val) {
      this.isLoading = true;
      this.departement = val;
      this.dashboard = {
        prevu: 0,
        contribuables: 0,
        agents: 0, paiement: 0
      };
      this.listQuartiers = [];
      this.listZones = [];
      this.listSecteurs = [];
      await this.ramificationRepo.search([{colonne: 'p.id', operator: 'eq', value: val.id}], 'ent.nom', 'asc', 30, 1)
          .then(response => {
            response.data.forEach(item => {
              this.listQuartiers.push({id: item.id, text: item.nom})

            })
            this.fillTable();

          })
    },
    changeQuartier(val) {
      this.selectedQuartier = val;
    },
    selectQuartier(val) {
      this.isLoading = true;
      this.quartier = val;
      this.dashboard = {
        prevu: 0,
        contribuables: 0,
        agents: 0,
        paiement: 0
      };
      this.listZones = [];
      this.listSecteurs = [];
      this.ramificationRepo.search([{colonne: 'p.id', operator: 'eq', value: val.id}], 'ent.nom', 'asc', 30, 1)
          .then(response => {
            response.data.forEach(item => {
              this.listZones.push({id: item.id, text: item.nom})
            })
            this.fillTable();
          })


    },
    contribuables() {
      return this.listContribuables.filter(item => item.statut = 'Validé');
    },

    exportToPDF() {
      html2pdf(document.getElementById("element-to-convert"), {
        margin: 1,
        html2canvas: {scale: 2},
        pagebreak: {mode: ['avoid-all', 'css', 'legacy']},
        filename: "liste-contribuables.pdf",
        jsPDF: {unit: 'mm', format: 'a0', orientation: 'landscape'}
      });
    },
    changeZone(val) {
      this.selectedZone = val;
    },
    selectZone(val) {
      this.isLoading = true;
      this.zone = val;
      this.dashboard = {
        prevu: 0,
        contribuables: 0,
        agents: 0,
        paiement: 0
      };
      this.listSecteurs = [];
      this.ramificationRepo.search([{colonne: 'p.id', operator: 'eq', value: val.id}], 'ent.nom', 'asc', 30, 1)
          .then(response => {
            response.data.forEach(item => {
              this.listSecteurs.push({id: item.id, text: item.nom})
            })
            this.fillTable();
          })

    },
    onDateChange() {
      this.refresh();
    },
    filterByDay() {
      this.daterange = moment().format("YYYY-MM-DD") + ' au ' + moment().format("YYYY-MM-DD");
      //console.log(this.ramificationId);
      this.fillTable();
      this.listeRamification = false;
      //this.refresh();
    },
    filterByYesterday() {
      this.daterange = moment().subtract(1, 'days').format("YYYY-MM-DD") + ' au ' + moment().subtract(1, 'days').format("YYYY-MM-DD");
      this.fillTable()
      this.listeRamification = false,
          //this.refresh();
          console.log(this.daterange)
    },
    filterByLastWeek() {
      this.daterange = moment().subtract(1, 'weeks').startOf('week').format("YYYY-MM-DD") + ' au ' + moment().subtract(1, 'weeks').endOf('week').format("YYYY-MM-DD");
      this.fillTable()
      this.listeRamification = false;
      //this.refresh();
      console.log(this.daterange)
    },
    filterByLastMonth() {
      this.daterange = moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD") + ' au ' + moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD");
      this.fillTable();
      this.listeRamification = false;
      //this.refresh();
      console.log(this.daterange)
    },
    filterByLastYear() {
      this.daterange = moment().subtract(1, 'years').startOf('year').format("YYYY-MM-DD") + ' au ' + moment().subtract(1, 'years').endOf('year').format("YYYY-MM-DD");
      this.fillTable();
      this.listeRamification = false;

      // this.refresh();
      console.log(this.daterange)
    },
    filterByWeek() {
      this.daterange = moment().startOf('week').format("YYYY-MM-DD") + ' au ' + moment().endOf('week').format("YYYY-MM-DD");
      this.fillTable();
      this.listeRamification = false;
      //console.log(this.daterange);
      //this.refresh();
    },
    filterByMonth() {
      this.daterange = moment().startOf('month').format("YYYY-MM-DD") + ' au ' + moment().endOf('month').format("YYYY-MM-DD");
      console.log(this.daterange);
      this.fillTable();
      this.listeRamification = false;
      //this.refresh();
    },
    filterByYear() {
      this.daterange = moment().startOf('year').format("YYYY-MM-DD") + ' au ' + moment().endOf('year').format("YYYY-MM-DD");
      //console.log(this.daterange)
      this.fillTable();
      this.listeRamification = false;
    },
    deleteNatureActivite(id) {
      if (confirm("Etes-vous sûr de supprimer cet élément ?")) {
        this.loading = true;
        this.repository.supprimer(id);
        this.fillTable();
        this.loading = false;
      }


    },
    bulkDelete() {
      if (confirm("Etes vous sûr de vouloir supprimer cet élément ?")) {
        this.loading = true;

        this.selectedItems.forEach(element => {
          this.repository.supprimer(element);
          //console.log(element);
        })
        this.loading = false;
        this.fillTable();
      }

    },
    selectAll() {
      if (this.allSelected === false) {
        this.allSelected = true;
        this.selectedItems = this.listContribuables.map(type => type.id);
        //  console.log(this.selectedItems);
      } else {
        this.allSelected = false;
        this.selectedItems = [];

        // console.log(this.selectedItems);

      }

      //console.log(this.allSelected)
    },
    goToPage(page) {
      this.currentPage = page;
      this.$router.replace({name: "listeContribuables", query: {q1: "q1"}})
      this.fillTable();
    },
    addFields() {
      this.fields.push({colonne: '', operator: '', value: ''});
    },
    removeField(index) {
      this.fields.splice(index, 1);
    },

    handleItemPerPage(event) {
      this.itemsPerPage = event.target.value;
      //   console.log('total de page ' + this.pages)
      // console.log(this.itemsPerPage)
      this.currentPage = 1;
      this.$router.replace({
        name: "listeContribuables",
        query: {itemsPerPage: event.target.value, orderBy: this.orderBy}
      })

      this.fillTable();


    },
    handleSearch() {
      //console.log(this.fields);
      this.fillTable();
    },
    handleOrderBy(event) {
      this.$router.replace({name: "listeContribuables", query: {orderBy: event.target.value}})

      this.orderBy = event.target.value;
      this.fillTable();

      //console.log(this.orderBy)

    },
    handleSortOrder(event) {
      this.sortOrder = event.target.value;
      // console.log(this.sortOrder)
      this.fillTable();

    },
    async fillTable() {
      //this.isLoading = true;
      this.from = this.daterange.includes('au') ? this.daterange.split('au')[0] : this.daterange;
      this.to = this.daterange.includes('au') ? this.daterange.split('au')[1] : this.daterange;


      await this.repository.findOne("/v2/statistiques/transactions/par-entite?entite=" + this.ramificationId + "&from=" + this.from + "&to=" + this.to)
          .then(response => {
            this.stats = response;
            this.isLoading = false;
            this.nombreTotalEnrol = this.stats.reduce((accumulator, currentValue) => accumulator + currentValue.total, 0);
          });
    },

  },
  created() {
    this.listQuartiers = [];
    this.listZones = [];
    this.listSecteurs = [];
    this.ramificationRepo.search([{colonne: 'p.id', operator: 'eq', value: 143}], 'ent.nom', 'asc', 30, 1)
        .then(response => {
          response.data.forEach(item => {
            this.listQuartiers.push({id: item.id, text: item.nom})

          })
          this.fillTable();
          console.log(this.listQuartiers);
        })
    this.selectedEntite = store.state.selectedCollectivite;
    this.$store.dispatch("setUser", JSON.parse(localStorage.getItem('user')));
    this.user = this.$store.state.user;

    //this.fields.push({colonne: 'contrib.statut', operator: 'eq', value: 'Validé'})
    this.query = Object.values(this.$route.query);
    //console.log(this.query)
    this.entite = {id: this.query[0]};
    this.departement = {id: this.query[1]};
    this.quartier = {id: this.query[2]};
    this.zone = {id: this.query[3]};
    this.secteur = {id: this.query[4]};
    if (this.daterange !== '')
      this.daterange = this.query[5];
    else
      this.daterange = moment().format("YYYY-MM-DD") + ' au ' + moment().format("YYYY-MM-DD");


    // console.log(this.daterange)

    this.ramificationRepo.search([{colonne: '', operator: '', value: ''}], 'ram.nom', 'asc', 500, 1)
        .then(response => {
          this.listRamifications = response.data.sort(function (a, b) {
            return b.situations.totalTransactions - a.situations.totalTransactions
          });
          console.log(this.listQuartiers)
        })
    this.entiteRepo.search([{colonne: 'ent.enabled', operator: 'eq', value: 1}], 'ent.nom', 'asc', 30, 1)
        .then(response => {
          this.ids = [];
          this.ids = response.data.map(item => item.id);
          response.data.forEach(item => {
            this.listCollectivites.push({id: item.id, text: item.nom});
            //this.fillTable();
          })
        })

  },
  components: {
    Loading,
    Select2
  }
}
</script>
<template>
  <!-- start page title -->
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <h4 class="mb-sm-0">Agents</h4>

        <div class="page-title-right">
          <ol class="breadcrumb m-0">
            <li class="breadcrumb-item"><router-link :to="{ name: 'home' }">Tableau de bord</router-link></li>
            <li class="breadcrumb-item"><router-link :to="{ name: 'home' }">Administration</router-link></li>
            <li class="breadcrumb-item active">Agents</li>
          </ol>
        </div>

      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="row">
        <form @submit.prevent="handleSearch">
          <div class="card">
            <div class="card-body">
              <table class="table table-sm table-bordered">
                <thead>
                <th>Champ</th>
                <th>Operation</th>
                <th>Valeur</th>
                </thead>
                <tbody>
                <tr v-for="(field, index) in fields" :key="index">
                  <td>
                    <select  v-model="field.colonne" class="form-select form-select-sm " style="width: 100%;">
                      <option v-bind:key="option.name" v-bind:value="option.name" v-for="option in repository.orderByOptions" >
                        {{ option.text }}
                      </option>
                    </select>
                  </td>
                  <td>
                    <select v-model="field.operator" class="form-select form-select-sm">
                      <option v-bind:key="option.name" v-for="option in repository.operatorOptions" v-bind:value="option.value">{{ option.text }}</option>
                    </select>
                  </td>
                  <td v-if="field.colonne === 'user.enabled' && field.operator==='eq' ">
                    <select v-model="field.value" class="form-select form-select-sm">
                      <option v-for="statut in repository.statutOptions" v-bind:value="statut" :key="statut">{{ statut }}</option>

                    </select>
                  </td>
                  <td v-else-if="field.colonne === 'user.enabled' && field.operator==='in' ">
                    <Select2MultipleControl @change="changeStatut($event, index)"  v-model="field.value" :options="repository.statutOptions"  class="form-select form-select-sm"></Select2MultipleControl>
                  </td>
                  <td v-else-if="field.colonne === 'ram.id' && field.operator==='eq' ">
                    <Select2 @change="changeStatut($event, index)"  v-model="field.value" :options="ramifications"  class="form-select form-select-sm"></Select2>
                  </td>
                  <td v-else-if="field.colonne === 'ram.id' && field.operator==='in' ">
                    <Select2MultipleControl @change="changeStatut($event, index)"  v-model="field.value" :options="ramifications"  class="form-select form-select-sm"></Select2MultipleControl>

                  </td>
                  <td v-else-if="field.colonne === 'user.createdAt' && field.operator==='eq' ">
                    <Flatpickr
                        v-model="field.value"
                        :config="dateConfig"
                        class="form-control form-select-sm "
                        placeholder="Select date"/>
                  </td>
                  <td v-else-if="field.colonne === 'user.createdAt' && field.operator==='like' ">
                    <Flatpickr
                        v-model="field.value"
                        :config="dateConfig"
                        class="form-control form-select-sm"
                        placeholder="Select date"/>
                  </td>
                  <td v-else-if="field.colonne === 'user.createdAt' && field.operator==='dateBetween' ">
                    <Flatpickr
                        v-model="field.x"
                        :config="dateConfig"
                        class="form-control form-select-sm"
                        placeholder="Select date"/>
                    <Flatpickr
                        v-model="field.y"
                        :config="dateConfig"
                        class="form-control form-select-sm"
                        placeholder="Select date"/>
                  </td>
                  <td v-else-if="field.colonne === 'ent.id' && field.operator==='eq' ">
                    <Select2  v-model="field.value" :options="collectivites"  class="form-select form-select-sm"></Select2>

                  </td>
                  <td v-else-if="field.colonne === 'ent.id' && field.operator==='in' ">
                    <Select2MultipleControl  @change="changeCollectivite($event, index)"   v-model="field.value" :options="collectivites"  class="form-select form-select-sm"></Select2MultipleControl>

                  </td>

                  <td v-else-if="field.operator==='between' ">
                    <input v-model= "field.x" type="text" class="form-control form-control-sm">

                    <input v-model= "field.y" type="text" class="form-control form-control-sm">

                  </td>
                  <td v-else>
                    <input v-model= "field.value" type="text" class="form-control form-control-sm">
                  </td>
                  <td>
                    <a @click="addFields" class="btn btn-sm btn-success"><i class="ri-add-line"/> </a>
                    <a  @click="removeField(index)" class="btn btn-sm btn-danger"><i class="ri-delete-bin-2-line"/> </a>
                  </td>
                </tr>

                </tbody>
              </table>
              <div class=" text-end">
                <button type="submit" class="btn btn-success float-right">Rechercher</button>
              </div>
            </div>

          </div>
        </form>
      </div>
      <div class="row">
        <div class="card card-animate">

          <div class="card-body">
            <div class="row right p-2">
              <div class="col-md-2 mt-4">
                <div class="btn-group " role="group">
                  <button id="btnGroupDrop1" type="button" class="btn btn-primary dropdown-toggle show" data-bs-toggle="dropdown" aria-expanded="true">
                    Action
                  </button>
                  <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1" data-popper-placement="top-start" data-popper-reference-hidden="" data-popper-escaped="" style="position: absolute; inset: auto auto 0px 0px; margin: 0px; transform: translate(0px, -40px);">
                    <li><a  @click="bulkDelete()" class="dropdown-item" href="#">Supprimer</a></li>
                    <li><a class="dropdown-item" href="#">Dropdown link</a></li>
                  </ul>
                </div>
              </div>
              <div class="col-md-2">
                <form @submit.prevent="handleItemPerPage">
                  <label>Count:</label>
                  <select v-bind:value="itemsPerPage" v-on:change="handleItemPerPage" class="form-select" >
                    <option v-bind:key="option.name" v-for="option in repository.itemsPerPageOptions" v-bind:value="option.value" v-bind:selected="option.value === itemsPerPage">
                      {{ option.text }}
                    </option>
                  </select>

                </form>
              </div>
              <div class="col-md-2">
                <label>Sort:</label>
                <select v-on:change="handleSortOrder" v-model="sortOrder"  class="form-select  " >
                  <option v-bind:key="option.value" v-for="option in repository.sortOrderOptions" v-bind:value="option.value" v-bind:selected="option.value === sortOrder">
                    {{ option.text }}
                  </option>
                </select>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>Order By:</label>
                  <select v-on:change="handleOrderBy"   v-bind:value="orderBy" class="form-select  " style="width: 100%;">
                    <option v-bind:key="option.text" v-for="option in repository.orderByOptions" v-bind:value="option.name" v-bind:selected="option.value === orderBy">
                      {{ option.text }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-3 right mt-4 ">
                <router-link :to="{name: 'createAgent'}" class="btn btn-block btn-outline-primary"><i class="ri-add-fill ri-1x"></i> Creation</router-link>
              </div>


            </div>

            <div class="row">
              <div class="table-responsive">
                <table class="table text-start mb-0">
                  <thead class="table-light">
                  <tr>
                    <th scope="col">
                      <div class="form-check">
                        <input v-model="allSelected" @click="selectAll" class="form-check-input" type="checkbox" value="" id="responsivetableCheck">
                        <label class="form-check-label" for="responsivetableCheck"></label>
                      </div>
                    </th>
                    <th scope="col">#</th>
                    <th scope="col">N°</th>
                    <th scope="col">Nom</th>
                    <th scope="col">Pseudo</th>
                    <th scope="col">Email</th>
                    <th scope="col">Statut</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, index) in listUtilisateurs" :key="item.id">
                    <td><input class="form-check-input" type="checkbox" v-model="selectedItems" v-bind:value="item.id" /></td>
                    <td>{{ index+1 }}</td>
                    <td>{{item.id}}</td>
                    <td>{{item.fullName}}</td>
                    <td>{{item.username}}</td>
                    <td>{{item.email}}</td>
                    <td>
                      <span v-if="item.enabled===true" class="badge bg-success">Active</span>
                      <span v-else class="badge bg-danger">inactive</span>
                    </td>
                    <td>
                      <div class="dropdown">
                        <a role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                          <i class="ri-more-2-fill"></i>
                        </a>

                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                          <li><router-link :to="{name: 'editAgent', params:{id: item.id}}" class="dropdown-item">Edit</router-link></li>
                          <li><a class="dropdown-item" @click="deleteAgent(item.id)">Delete</a></li>
                        </ul>
                      </div>
                    </td>
                  </tr>

                  </tbody>
                  <tfoot class="table-light">
                  <tr>
                    <th scope="col">
                      <div class="form-check">
                        <input v-model="allSelected" @click="selectAll" class="form-check-input" type="checkbox" value="" id="responsivetableCheck">
                        <label class="form-check-label" for="responsivetableCheck"></label>
                      </div>
                    </th>
                    <th scope="col">#</th>
                    <th scope="col">Nom</th>
                    <th scope="col">N°</th>
                    <th scope="col">Pseudo</th>
                    <th scope="col">Email</th>
                    <th scope="col">Statut</th>
                    <th></th>
                  </tr>
                  </tfoot>
                </table>
                <!-- end table -->
              </div>
            </div>
            <!-- end table responsive -->
          </div>
          <div class="card-footer">
            <div class="row">

              <div class="col-sm-12 col-md-12 ">
                <div style="display: flex; justify-content: flex-end" class="dataTables_paginate paging_simple_numbers text-end" id="scroll-horizontal_paginate">
                  <ul class="pagination">
                    <li v-if="totalPages<=1" class="paginate_button page-item previous disabled" id="scroll-horizontal_previous">
                      <a @click="goToPage((currentPage-1))" href="#" aria-controls="scroll-horizontal" data-dt-idx="0" tabindex="0" class="page-link">Précédent</a>
                    </li>
                    <li v-else-if="totalPages>1" class="paginate_button page-item previous disabled" id="scroll-horizontal_previous">
                      <a @click="goToPage((currentPage-1))" href="#" aria-controls="scroll-horizontal" data-dt-idx="0" tabindex="0" class="page-link">Précédent</a>
                    </li>
                    <li v-for="page in pages" :key="page" class="paginate_button page-item active">
                      <a  @click="goToPage(page)"   href="#" aria-controls="scroll-horizontal" data-dt-idx="1" tabindex="0" class="page-link">{{ page }}</a>
                    </li>
                    <li v-if="totalPages<=1" class="paginate_button page-item next disabled" id="scroll-horizontal_next">
                      <a @click="goToPage((currentPage+1))" href="#" aria-controls="scroll-horizontal" data-dt-idx="3" tabindex="0" class="page-link">Next</a>
                    </li>
                    <li v-else-if="totalPages>1" class="paginate_button page-item next " id="scroll-horizontal_next">
                      <a @click="goToPage((currentPage+1))" href="#" aria-controls="scroll-horizontal" data-dt-idx="3" tabindex="0" class="page-link">Next</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Utilisateur from "@/entity/User";
import Repository from '@/repository/AgentRepository'
import Select2MultipleControl from 'v-select2-multiple-component';
import Select2 from "vue3-select2-component";
import Flatpickr from "vue-flatpickr-component";
import RamificationRepository from "@/repository/RamificationRepository";

import EntiteRepository from "@/repository/EntiteRepository";
import fr from "flatpickr/dist/l10n/fr";
export default {
  name: 'listUtilisateurPage',
  data() {
    return {
      user: null,
      utilisateur: new Utilisateur(),
      repository: new Repository(),
      orderBy: 'user.nom',
      sortOrder: 'asc',
      itemsPerPage: 30,
      allSelected: false,
      selectedItems: [],
      listCollectivites: [],
      listUtilisateurs: null,
      listRamifications: [],
      totalPages: 1,
      currentPage: 1,
      from: null,
      to: null,
      ramificationRepo: new RamificationRepository(),
      entiteRepo: new EntiteRepository(),
      loading: false,
      fields: [
        { colonne: '', operator: '', value: '' }
      ]

    }
  },
  computed: {
    ramifications(){
      return this.listRamifications.map(item=>({id: item.id, text: item.nom}))
    },
    users(){
      return this.listUsers.map(item=>({id: item.id, text: item.fullName}))
    },
    collectivites(){
      return this.listCollectivites.map(item => ({id: item.id, text: item.text}));
    },
    config(){
      //var current_month = 12 - new Date().getMonth();
      return   {
        locale: fr.fr,
        mode: "range",
        dateFormat: "Y-m-d",
        firstDayOfWeek: 1,
        defaultDate: (this.selectedAnnee != null)?[this.selectedAnnee.text +"-" +new Date().getMonth()+"-01", this.selectedAnnee.text +"-12-"+new Date().getDay()]:[new Date().getFullYear()+'-'+ new Date().getMonth()+"-01", new Date().getFullYear()+'-'+new Date().getFullYear()+'-'+ new Date().getMonth()+"-31"],
      }

    },
    dateConfig(){
      //var current_month = 12 - new Date().getMonth();
      return   {
        locale: fr.fr,
        dateFormat: "Y-m-d",
        firstDayOfWeek: 1,
        defaultDate: (this.selectedAnnee != null)?[this.selectedAnnee.text +"-" +new Date().getMonth()+"-01", this.selectedAnnee.text +"-12-"+new Date().getDay()]:[new Date().getFullYear()+'-'+ new Date().getMonth()+"-01", new Date().getFullYear()+'-'+new Date().getFullYear()+'-'+ new Date().getMonth()+"-31"],
      }

    },
    pages() {
      // Calculer le nombre de pages en arrondissant à l'entier supérieur

      // Créer un tableau allant de 1 à numPages
      return Array.from({length: this.totalPages}, (_, i) => i + 1)
    }
  },
  methods: {
    changeStatut(val, index){
      this.fields[index].value = val;
      console.log(val)

    },
    changeCollectivite(val, index){
      this.fields[index].value = val;
      console.log(val)

    },
    changeRamification(val, index){
      this.fields[index].value = val;
      console.log(val)

    },
    deleteAgent(id){
      if(confirm("Etes-vous sûr de supprimer cet élément ?")){
        this.loading = true;
        this.repository.supprimer(id);
        this.fillTable();
        this.loading = false;
      }


    },
    bulkDelete(){
      if(confirm("Etes vous sûr de vouloir supprimer cet élément ?")){
        this.loading = true;

        this.selectedItems.forEach(element => {
          this.repository.supprimer(element);
          //console.log(element);
        })
        this.loading = false;
        this.fillTable();
      }

    },
    selectAll() {
      if (this.allSelected === false){
        this.allSelected = true;
        this.selectedItems = this.listUtilisateurs.map(type => type.id);
        //  console.log(this.selectedItems);
      }
      else{
        this.allSelected = false;
        this.selectedItems = [];

        // console.log(this.selectedItems);

      }

      //console.log(this.allSelected)
    },
    goToPage(page){
      this.currentPage = page;
      this.fillTable();
    },
    addFields() {
      this.fields.push({ colonne: '', operator: '', value: '' });
    },
    removeField(index) {
      this.fields.splice(index, 1);
    },
    handleItemPerPage(event){
      this.itemsPerPage = event.target.value;
      //   console.log('total de page ' + this.pages)
      // console.log(this.itemsPerPage)
      this.currentPage=1;

      this.fillTable();


    },
    handleSearch(){
      //console.log(this.fields);
      this.currentPage = 1;
      this.fillTable();
    },

    handleOrderBy(event){
      this.orderBy = event.target.value;
      this.fillTable();

      //console.log(this.orderBy)

    },
    handleSortOrder(event){
      this.sortOrder = event.target.value;
      // console.log(this.sortOrder)
      this.fillTable();

    },
    async fillTable(){
      try {

        const trResponse = await this.repository.search(this.fields, this.orderBy, this.sortOrder, this.itemsPerPage, this.currentPage);
        this.listUtilisateurs = trResponse.data;
        //console.log(this.listUtilisateurs);
        this.totalPages = Math.ceil(trResponse.meta.total_records / this.itemsPerPage);

      }catch (e) {
        console.log(e);
      }
    },


  },
  created() {
    this.$store.dispatch("setUser", JSON.parse(localStorage.getItem('user')));
    this.user = this.$store.state.user;


    this.ramificationRepo.search([{colonne: 'ram.enabled', operator: 'eq', value: 1}], 'ent.nom', 'asc', 3000, 1)
        .then(response => {
          this.listRamifications = response.data;
          //     this.fillTable();
        })

    this.entiteRepo.search([{colonne: 'ent.enabled', operator: 'eq', value: 1}], 'ent.nom', 'asc', 30, 1)
        .then(response => {
          this.ids = [];
          this.ids = response.data.map(item => item.id);
          response.data.forEach(item => {
            this.listCollectivites.push({id: item.id, text: item.nom});

          })


        })
    this.fillTable();
  },
  components:{
    Select2,
    Flatpickr,
    Select2MultipleControl



  }
}
</script>
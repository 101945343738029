import ListeAgent from "@/components/agent/ListeAgent";
import CreateAgent from "@/components/agent/CreateAgent";
import EditAgent from "@/components/agent/EditAgent";


export default [

    {
        path: '/collectivite/agent',
        name: 'listAgent',
        component: ListeAgent
    },
    {
        path: '/collectivite/agent/create',
        name: 'createAgent',
        component: CreateAgent
    },
    {
        path: '/collectivite/agent/:id',
        name: 'editAgent',
        component: EditAgent
    }
]